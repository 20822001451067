import React, { useState } from 'react'
//important for getting nice style.
import 'image-upload-react/dist/index.css'
import CustomInput from '../components/CustomInput'
import Spinner from '../components/Spinner';
import Modal from '../components/Modal';
import CustomSelect from '../components/CustomSelect';
import TypeIt from 'typeit-react';
import warningIcon from '../assets/warning-sign-svgrepo-com.svg'
import { COUNTRIES } from '../constants';

function FutureMarket() {
  const [code, setCode] = useState('');
  const [msg, setMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [type, setType] = useState();
  const [country, setCountry] = useState();
  const [paragraph, setParagraph] = useState();

  const submitForm = () => {
    if (isLoading) return;
    if (!type) {
      setMsg('Select type of unit!')
      setIsShow(true);
      return;
    }
    if (!code || code.trim() === '') {
      setMsg('Fill the prompt to generate!')
      setIsShow(true);
      return;
    }
    setParagraph('');
    setIsLoading(true);
    postPredict().then(function (response) {
      if (response.status === 205) {
        setMsg('This version only allow to generate 5 times a day for every IP address. Try again next day!');
        setIsShow(true);
        return;
      }
      response.json().then(data => setParagraph(data.text));
    }).catch((err) => {
      console.log(err)
      setMsg('Failed process. Wait a couple minutes and try again!')
      setIsShow(true);
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const postPredict = async () => {
    var prompt = `Analyse ${type} ${code}`;
    if (country) {
      prompt += ` in ${country}`;
    }
    var param = new URLSearchParams({
      prompt: `${prompt} in the future.`,
    })

    const res = await fetch('https://invest-api.futuai.net/chat?' + param, {
      method: 'GET',
    })
    return res;
  }

  const ressetAll = () => {
    setCode('');
    setType(null);
    setCountry(null);
  }
  return (
    <>
      <Modal
        isShow={isShow}
        onClose={() => setIsShow(false)}
        content={msg}
      />
      <div className='main-bg' style={{ height: '15rem' }}>
        <header className="container pb-2" style={{ height: '30%' }}>
          <div className="text-align-center" style={{ height: '100%', width: '100%' }}>
            <h1 className="balance-text mb-1 color-white text-align-center text-bold">AI Predictive Analytics
            </h1>
          </div>
        </header>
      </div>
      <section className="bg-fg-3 py-1">
        <div className="container">
          <div className="row d-md-none">
            <div className="col-3 col-md-2">
              <div className='mt-1'>
                <CustomSelect
                  title={'Type'}
                  placeholder={'Type'}
                  onChange={val => setType(val)}
                  value={type}
                  disabled={isLoading}
                  options={[
                    { value: 'Stock', label: 'Stock'},
                    { value: 'Coin', label: 'Coin'}
                  ]}
                />
              </div>
            </div>
            <div className="col-3 col-md-2">
              <div className='mt-1'>
                <CustomInput
                  title={'Code'}
                  value={code}
                  onChange={val => setCode(val)}
                  disabled={isLoading}
                  placeholder="Code"
                />
              </div>
            </div>
            <div className="col-4 col-md-2">
              <div className='mt-1'>
                <CustomSelect
                  title={'Country'}
                  placeholder={'Country'}
                  onChange={val => setCountry(val)}
                  value={country}
                  disabled={isLoading}
                  options={COUNTRIES.map(item => ({ value: item, label: item }))}
                />
              </div>
            </div>
            <div className="col-3 col-md-4 content no-col">
              <div className='mb-1 d-flex'>
                <span className="btn btn-padded btn-dark btn-circle mr-0.5" onClick={submitForm}>Predict</span>
                <span className="btn btn-padded btn-dark btn-circle mr-0.5" onClick={ressetAll}>Reset</span>
              </div>
              {/* <h2 className="output-box">Output</h2> */}
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 content no-col d-flex justify-content-center">
              <div style={{position: 'relative', width: '100%'}}>
                <div
                  className='d-flex'
                  style={{
                    width: '100%',
                    minHeight: 400,
                    margin: 0,
                    whiteSpace: 'pre-wrap',
                    alignItems: isLoading ? 'center' : 'start'
                  }}
                >
                  {paragraph
                    && !isLoading
                    && <TypeIt
                      options={{
                        strings: [paragraph],
                        speed: 5,
                        waitUntilVisible: true,
                      }}
                    />
                  }
                  {isLoading && <Spinner />}
                  {!isLoading && !paragraph && <div className='no-content-container m-auto'>
                    <img src={warningIcon} alt="Logo icon" height={'50px'} style={{ objectFit: 'contain', margin: 'auto' }} />
                    <div style={{ color: '#7e7e7e', margin: 'auto' }}>No content</div>
                  </div>}
                </div>
              </div>
            </div>
            <div className="col-md-4 d-none d-md-block" style={{ borderLeft: '2px solid #ccc', paddingLeft: 10, marginBottom: 10 }}>
              <div className='mt-1'>
                <CustomSelect
                  title={'Type'}
                  placeholder={'Type'}
                  onChange={val => setType(val)}
                  value={type}
                  disabled={isLoading}
                  options={[
                    { value: 'Stock', label: 'Stock' },
                    { value: 'Coin', label: 'Coin' }
                  ]}
                />
              </div>
              <div className=''>
                <CustomInput
                  title={'Code'}
                  value={code}
                  onChange={val => setCode(val)}
                  disabled={isLoading}
                  placeholder="Code"
                />
              </div>
              <div className=''>
                <CustomSelect
                  title={'Country'}
                  placeholder={'Country'}
                  onChange={val => setCountry(val)}
                  value={country}
                  disabled={isLoading}
                  options={COUNTRIES.map(item => ({value: item, label: item}))}
                />
              </div>
              <div className='d-flex justify-content-end'>
                <span className="btn btn-padded btn-dark btn-circle mr-0.5" onClick={submitForm}>Predict</span>
                <span className="btn btn-padded btn-dark btn-circle mr-0.5" onClick={ressetAll}>Reset</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FutureMarket;
