import '../styles/dropdown.css'

function Dropdown(props) {
  // const [isShow, setIsShow] = useState(false);
  const { title, onDisconnect } = props;

  const openMenu = () => {
    // const ele = document.getElementsByClassName('sub-menu')[0];
    // if (ele) {
    //   if (!isShow) {
    //     ele.classList.add('visible');
    //   } else {
    //     ele.classList.remove('visible');
    //   }
    // }
    // setIsShow(!isShow);
  }

  return <>
    <li className="sub-menu-parent" tab-index="0">
      <span onClick={openMenu} className="btn btn-padded btn-dark btn-circle m-0">{title}</span>
      <ul className="sub-menu">
        <li onClick={onDisconnect}><span>Disconnect</span></li>
      </ul>
    </li>
  </>
}

export default Dropdown;