import React, { useState } from 'react'
//important for getting nice style.
import 'image-upload-react/dist/index.css'
import CustomInput from '../components/CustomInput'
import Spinner from '../components/Spinner';
import Modal from '../components/Modal';

function Application() {
  const [outputSrc, setOutputSrc] = useState();
  const [description, setDescription] = useState('');
  const [msg, setMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const submitForm = () => {
    if (isLoading) return;
    if (!description || description.trim() === '') {
      setMsg('Fill the prompt to generate!')
      setIsShow(true);
      return;
    }
    setOutputSrc(null);
    setIsLoading(true);
    postGenAI().then(function (response) {
      if (response.status === 205) {
        setMsg('This version only allow to generate 5 times a day for every IP address. Try again next day!');
        setIsShow(true);
        return;
      }
      response.json().then(data => setOutputSrc(data.image));
    }).catch((err) => {
      console.log(err)
      setMsg('Failed process. Wait a couple minutes and try again!')
      setIsShow(true);
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const postGenAI = async () => {
    var param = new URLSearchParams({
      prompt: 'mdjrny-v4 style ' + String(description).trim(),
    })

    const res = await fetch('https://api.futuai.net/api/v1/predictions?' + param, {
      method: 'POST',
    })
    return res;
  }
  return (
    <>
      <Modal
        isShow={isShow}
        onClose={() => setIsShow(false)}
        content={msg}
      />
      <div className='main-bg' style={{ height: '15rem' }}>
        <header className="container pb-2" style={{ height: '30%' }}>
          <div className="text-align-center" style={{ height: '100%', width: '100%' }}>
            <h1 className="balance-text mb-1 color-white text-align-center text-bold">AI Avatar NFT Generation
            </h1>
          </div>
        </header>
      </div>
      <section className="bg-fg-3 py-1">
        <div className="container">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8 col-xl-4">
              <div className='mt-1'>
                <CustomInput
                  title={'Prompt'}
                  value={description}
                  onChange={val => setDescription(val)}
                  disabled={isLoading}
                  placeholder={"Prompt to guide the image generation"}
                />
              </div>
              {/* <h2 className="mb-1">Input</h2>
              <div className='image-holder'>
                <ImageUpload
                  handleImageSelect={handleInputImageSelect}
                  imageSrc={inputSrc ? URL.createObjectURL(inputSrc) : ''}
                  setImageSrc={setInputSrc}
                  style={{
                    width: '100%',
                    height: 400,
                    boxShadow: 'none',
                    backgroundColor: 'rgba(1,1,1,0)',
                    margin: 0,
                  }}
                />
                {isLoading && <div
                  style={{
                    width: 'calc(100% - 20px)',
                    height: 400,
                    margin: 0,
                    position: 'absolute',
                    top: '10px'
                  }}
                >
                </div>}
              </div> */}
            </div>
            <div className="col-2 d-xl-none"></div>
            <div className="col-2 d-xl-none"></div>
            <div className="col-10 col-xl-4 content no-col">
              <div className=' mt-0 mt-xl-1 mb-1'>
                <span className="btn btn-padded btn-dark btn-circle mr-0.5" onClick={submitForm}>GENERATE AI AVATAR</span>
                {outputSrc && !isLoading && <span className="btn btn-padded btn-dark btn-circle d-inline-block mr-0.5 tooltip">MINT AI NFT</span>}
              </div>
              {/* <h2 className="output-box">Output</h2> */}
            </div>
            <div className="col-2"></div>
          </div>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8 content no-col d-flex justify-content-center">
              <div className='image-holder'>
                <div
                  style={{
                    width: '100%',
                    height: 400,
                    margin: 0
                  }}
                >
                  {outputSrc && !isLoading && <img src={outputSrc} alt='output' height={400} width='100%' style={{ objectFit: 'contain' }} />}
                  {isLoading && <Spinner />}
                </div>
              </div>
              </div>
              <div className="col-2"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Application;
