
import '../styles/spinner.css';

function Spinner() {
  return <div className='d-flex align-items-center' style={{
    width: '100%',
    height: '100%'
  }}>
    <div className="lds-roller m-auto">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
}

export default Spinner