import '../styles/modal.css'
function Modal(props) {
  const { isShow, onClose, content } = props;
  return <div id="open-modal" className={`modal-window ${isShow ? 'visible' : ''}`}>
    <div className="cookiesContent" id="cookiesPopup">
      <button className="close" onClick={onClose}>✖</button>
      <img src="https://cdn-icons-png.flaticon.com/512/1047/1047711.png" alt="cookies-img" />
      <p>{content}</p>
      <button className="accept" onClick={onClose}>I understand!</button>
    </div>
  </div>
}

export default Modal;