import '../styles/custominput.css'

const CustomInput = (props) => {
  const { title, value, onChange, disabled, placeholder } = props
  return <>
    <div className="form__group">
      <input
        type="text"
        className="form__input"
        id="name"
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoComplete="off"/>
      <label htmlFor="name" className="form__label">{title}</label>
    </div>
  </>
}

export default CustomInput;