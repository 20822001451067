import { useState } from 'react';
import '../styles/custominput.css'

const CustomSelect = (props) => {
  const { title, onChange, disabled, options, placeholder, value } = props
  const [isFocusing, setIsFocusing] = useState(false);

  const onChangeSelected = (val) => {
    onChange(val);
  }

  return <>
    <div className="form__group">
      <input
        type="text"
        className="form__input select-box"
        id="name"
        placeholder={placeholder}
        disabled={disabled}
        value={value ? (options?.find(item => item.value === value)?.label || null) : ''}
        onFocus={() => setIsFocusing(true)}
        onChange={() => { }}
        onBlur={() => setTimeout(() => setIsFocusing(false), 200)} onClick={e => e.preventDefault()}
        onKeyDown={(e) => e.preventDefault()}
        autoComplete="off" />
      <label htmlFor="name" className="form__label">{title}</label>
      <div style={{position: 'relative'}}>
        <ul style={{ display: isFocusing ? 'block' : 'none' }}>
          {options?.map((item, idx) => (<li key={idx} onClick={() => onChangeSelected(item.value)}>
            {item.label}
          </li>))}
        </ul>
      </div>
    </div>
  </>
}

export default CustomSelect;